import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Testimonials from "../components/testimonials"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"
import GoogleMap from "../components/map"
import WestSidePhoto from "../images/office/west-side-sign.jpg"
import "../components/map/map-styles.scss"
import testimonialList from '../components/testimonials/list.json'
import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal'
import utes from '../images/utes-logo.svg'
import marquette from '../images/marquette-university.svg'
import viewmont from '../images/viewmont.png'
import procedures from './procedures.json'
export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      show: null,
      modalCurrent: 0,
      testimonials: props.testimonials,
      procedureModalOpen: false,
      procedureTitle: null,
      procedureBody: null
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  handleClose() {
    this.setState({show: null});
  }

  handleShow(id) {
    const modalData = procedures.filter(p => id === p.id)
    this.setState({procedureTitle: modalData[0].title})
    this.setState({procedureBody: modalData[0].body})
    this.setState({show: id});
  }

  render() {

    return (
      <Layout>
        <SEO title="Home" />

        <section className="page-section bg-white" id="jumplinks">
          <div className="container">
            <h2 className="text-center mt-0">At Your Service</h2>
            <hr className="divider my-4" />
            <div className="row">
              <div className="col-lg-4 col-md-6 text-center">
                <div className="mt-5">
                  <a href="https://forms.mydentistlink.com/e055f151-27df-49e2-866a-80263f847c64" target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-4x fa-clipboard text-primary mb-4"></i>
                    <h3 className="h4 mb-2">New Patient Forms</h3>
                    <p className="text-muted mb-0">Skip the clipboard! Fill out the forms before you arrive.</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center">
                <div className="mt-5">
                  <a href="#testimonials">
                    <i className="fas fa-4x fa-comments text-primary mb-4"></i>
                    <h3 className="h4 mb-2">What our patients are saying</h3>
                    <p className="text-muted mb-0">We love our patients, and they love us! See what they have to say.</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center">
                <div className="mt-5">
                  <a href="https://www.google.com/maps/dir/40.7748139,-111.9049906/MacKay+Dental+Care,+280+N+200+W+%23100,+Bountiful,+UT+84010/@40.833498,-111.9721113,12z/data=!3m1!4b1!4m17!1m6!3m5!1s0x8752f8213b8da61b:0xb1673136a11e2f89!2sMacKay+Dental+Care!8m2!3d40.8922245!4d-111.8853135!4m9!1m1!4e1!1m5!1m1!1s0x8752f8213b8da61b:0xb1673136a11e2f89!2m2!1d-111.8853135!2d40.8922245!3e1?hl=en-US">
                    <i className="fas fa-4x fa-map-signs text-primary mb-4"></i>
                    <h3 className="h4 mb-2">Directions to our office</h3>
                    <p className="text-muted mb-0">Our office is conveniently located near Costco. We look forward to seeing you soon!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className="page-section bg-primary" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <h2 className="text-white mt-0">About MacKay Dental Care</h2>
                <hr className="divider light my-4" />
              </div>
              <div className="col-lg-5 col-lg-offset-1 text-left">
                <p className="text-white-50 mb-4"></p>
                <p>Doctor MacKay was born and raised in Bountiful and Centerville where he attended Viewmont High School. He married his wife Amy, also from Bountiful, and together they have 5 children. They currently live in Centerville, and they love working and living in the community. They enjoy camping, hiking, fishing, mountain biking, outdoor adventures, traveling and most any sport.</p>
                <p>From an early age, Matthew MacKay wanted to become a dentist. He grew up surrounded by family members who worked in the medical/health care profession, and he has always been involved with caring for others. He enjoys serving and helping others improve their oral health. “It's about helping others have a healthy smile,” says Matt. “Everyone should have a healthy smile.”</p>
              </div>
              <div className="col-lg-5 col-lg-offset-1 text-left">
                <p className="text-white-50 mb-4"></p>
                <p>Education Education has always been an important part of Doctor MacKay's career. Below is a list of schools where he has gained his experience and training.</p>

                <ListGroup>
                  <ListGroup.Item variant="dark"><img src={viewmont} style={{ width: '25px' }} alt="Viewmont High School Logo" /> Viewmont High School </ListGroup.Item>
                  <ListGroup.Item variant="dark"><img src={utes} style={{ width: '25px' }} alt="University of Utah Logo" /> University of Utah : Bachelors of Science (Medical Biology)</ListGroup.Item>
                  <ListGroup.Item variant="dark"><img src={marquette} style={{ width: '25px' }} alt="Marquette University Logo" /> Marquette University School of Dentistry : Doctor of Dental Surgery (DDS)</ListGroup.Item>
                  <ListGroup.Item variant="dark">SLC VA Hospital: General Practice Residency (Advanced Training)</ListGroup.Item>
                </ListGroup>

                <br />
                <a className="btn btn-light btn-xl js-scroll-trigger" href="#services"
                  onClick={Scroller.handleAnchorScroll}>See What MacKay Dental Care Has To Offer</a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="services">
          <div className="container">
            <h2 className="text-left mt-0">Procedures</h2>
            <hr align="left" className="divider my-4 text-left" />
            <div className="row">
              <div className="col-lg-6 col-md-6 text-left">
                <div className="mt-5">
                  <h3 align="left" className="h4 mb-2">General Dentistry</h3>
                  <ul align="left" className="text-muted">
                    <li><a onClick={() => this.handleShow('cleaning')}>Scheduled Cleaning &amp; Exams</a></li>
                    <li><a onClick={() => this.handleShow('pediatric')}>Pediatric Dentistry</a></li>
                    <li><a onClick={() => this.handleShow('salants')}>Sealants &amp; Fluoride</a></li>
                    <li><a onClick={() => this.handleShow('fillings')}>Tooth Colored Fillings</a></li>
                    <li><a onClick={() => this.handleShow('root-canal')}>Root Canal Therapy</a></li>
                    <li><a onClick={() => this.handleShow('dentures')}>Dentures &amp; Partials</a></li>
                    <li><a onClick={() => this.handleShow('gum-disease')}>Periodontal (Gum) Treatments</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-left">
                <div className="mt-5">
                  <h3 align="left" className="h4 mb-2">Cosmetic Dentistry</h3>
                  <ul align="left" className="text-muted">
                    <li><a onClick={() => this.handleShow('whitening')}>Teeth Whitening</a></li>
                    <li><a onClick={() => this.handleShow('orthodontics')}>Orthodontics (Invisalign)</a></li>
                    <li><a onClick={() => this.handleShow('crowns')}>Crowns &amp; Bridges</a></li>
                    <li><a onClick={() => this.handleShow('veneers')}>Porcelain Veneers</a></li>
                    <li><a onClick={() => this.handleShow('implants')}>Dental Implants</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/office/fullsize/1.jpg" onClick={this.handlePortfolioClick.bind(this, 0)}>
                  <Img fluid={this.props.data.images.edges[0].node.childImageSharp.fluid} />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Best of Class Dental Office
                    </div>
                    <div className="project-name">
                      An Exam Room With a View (Or a Movie)
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/office/fullsize/2.jpg" onClick={this.handlePortfolioClick.bind(this, 1)}>
                  <Img fluid={this.props.data.images.edges[1].node.childImageSharp.fluid} />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Well Educated and Friendly
                    </div>
                    <div className="project-name">
                      Because It Matters
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/office/fullsize/3.jpg" onClick={this.handlePortfolioClick.bind(this, 2)}>
                  <Img fluid={this.props.data.images.edges[2].node.childImageSharp.fluid} />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Highly Skilled Staff
                    </div>
                    <div className="project-name">
                      Extra Care &bull; Extra Clean
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="images/portfolio/fullsize/4.jpg" onClick={this.handlePortfolioClick.bind(this, 3)}>
                  <Img fluid={this.props.data.images.edges[3].node.childImageSharp.fluid} />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Office Staff
                    </div>
                    <div className="project-name">
                      Our Team is Friendly and Professional
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/office/fullsize/5.jpg" onClick={this.handlePortfolioClick.bind(this, 4)}>
                  <Img fluid={this.props.data.images.edges[4].node.childImageSharp.fluid} />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Regular Office Visits
                    </div>
                    <div className="project-name">
                      We Promote Effective Oral Care
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/office/fullsize/6.jpg" onClick={this.handlePortfolioClick.bind(this, 5)}>
                  <Img fluid={this.props.data.images.edges[5].node.childImageSharp.fluid} />
                  <div className="portfolio-box-caption p-3">
                    <div className="project-category text-white-50">
                      High Tech Dental Office
                    </div>
                    <div className="project-name">
                      Our Exam Rooms Are Fully Equipped
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="map" className="page-section bg-dark text-white">
          <div className="container text-center">
            <h2 className="mb-4">Visit Us Today</h2>
            {/* <a className="btn btn-light btn-xl" href="https://startbootstrap.com/themes/creative/">Download Now!</a> */}

          </div>
          <div id="wrapper" style={{ height: '100vh', width: '100%' }}>
            <div id="MacKayBanner">
              <img src={WestSidePhoto} style={{ width: '100%' }} alt="MacKay Dental Care from 200 West" />
              <address>
                <h1>MacKay Dental Care</h1>
                <h3>280 N 200 W #100<br />Bountiful, UT 84010</h3>

                <div>
                  <a href="https://www.google.com/maps/dir/40.7748139,-111.9049906/MacKay+Dental+Care,+280+N+200+W+%23100,+Bountiful,+UT+84010/@40.833498,-111.9721113,12z/data=!3m1!4b1!4m17!1m6!3m5!1s0x8752f8213b8da61b:0xb1673136a11e2f89!2sMacKay+Dental+Care!8m2!3d40.8922245!4d-111.8853135!4m9!1m1!4e1!1m5!1m1!1s0x8752f8213b8da61b:0xb1673136a11e2f89!2m2!1d-111.8853135!2d40.8922245!3e1?hl=en-US" target="_blank" rel="noopener noreferrer"><i className="fas fa-map-marker-alt"></i>&nbsp; Driving Directions</a>
                </div>
                <div>
                  <a href="tel:+1-801-397-2323"><i className="fas fa-phone"></i> Call Us! (801) 397-2323</a>
                </div>

              </address>
            </div>
            <GoogleMap id="google_map" lat={40.8922245} lng={-111.8875075} zoom={17} />
          </div>
        </section>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">Let's Get In Touch!</h2>
                <hr className="divider my-4" />
                <p className="text-muted mb-5">Let us know how we can help. We are happy to answer any questions you have. Especially if you have questions about your smile. </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <a href='tel:18013972323'>
                  <i className="d-block fas fa-phone fa-3x mb-3 text-muted"></i>
                  <div>+1 (801) 397-2323</div>
                </a>
              </div>
              <div className="col-lg-4 mr-auto text-center">
                <a className="d-block" href="mailto:mackaydentalcare@hotmail.com">
                  <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                  mackaydentalcare@hotmail.com</a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-primary" id="testimonials">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">Testimonials</h2>
                <hr className="divider light my-4" />
                <div className="text-white-50 mb-4" style={{ minHeight: '100px' }}>
                  <Testimonials testimonials={testimonialList} />
                </div>
                <a target="_blank" rel="noopener noreferrer" className="btn btn-light btn-xl" href="https://local.demandforce.com/b/MacKay-Dental-Care/reviews.page?header=true&d3cp_exid=MacKay-Dental-Care&d3cp_source=My%2520Website">Read More!</a>
              </div>
            </div>
          </div>
        </section>


        <PortfolioModal show={this.state.modalShow} onHide={() => this.setModal(false, 0)}>
          <PortfolioCarousel images={this.props.data.images.edges} current={this.state.modalCurrent} />
        </PortfolioModal>

        <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton closeLabel="close window">
          <h2>{this.state.procedureTitle}</h2>
          </Modal.Header>
          <Modal.Body style={{'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto'}} dangerouslySetInnerHTML={{__html: this.state.procedureBody}}>
          
          </Modal.Body>
        </Modal>

      </Layout>
    )
  }
}

export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "office/fullsize/*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
