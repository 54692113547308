import React from 'react'
import GoogleMapReact from 'google-map-react'
import './map-styles.scss'
import Logo from '../../images/brand/logo.svg'

const Marker = ({ details }) => {
  return (
    <div style={{ 
      display: 'flex',
      width: 100, 
      height: 100, 
      alignContent: 'space-between'
    }}>
        <i className="fas fa-4x fa-map-marker-alt" style={{color: 'red', display: 'inline-block' }} ></i>
        <img src={Logo} alt='{details.text}' style={{display: 'inline-block', width: '125px'}} />
    </div>
  )
}

class GoogleMap extends React.Component {

  state = {
    center: {
      lat: 40.892389,
      lng: -111.885331
    },
    zoom: 17
  }

  constructor(props) {
    super(props)
  }


  render() {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyBIAZvSPwbZW3U-ipuBs7j176WPqIyJvQk'
        }}
        center={this.state.center}
        defaultZoom={this.state.zoom}
      >
        <Marker lat={40.892389}
            lng={-111.885331}
            text="MacKay Dental Care" />
      </GoogleMapReact>
    )
  }

}

export default GoogleMap