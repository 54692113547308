import React, { Component } from 'react'

class Cycler extends Component {
  state = {
    buttonText: '',
    currentChild: 1,
    hover: false,
  }

  constructor (props) {
    super(props)
    this.state = {buttonText: this.props.children[0], currentChild: 0, hover: false}
  }

  hoverOn = () => {
    this.setState({
      hover: true,
    })
  }

  hoverOff = () => {
    this.setState({
      hover: false,
    })
  }

  getChildID () {
    return this.state.currentChild
  }

  componentDidMount () {
    this.timerID = setInterval(
      () => {
        if (!this.state.hover) {
          this.refresh(this.getChildID())
        }
      }, this.props.waitSeconds * 1000
    )
  }

  componentWillUnmount () {
    clearInterval(this.timerID)
  }

  refresh (childID) {
    const numberOfChildren = this.props.children.length

    this.setState({
      buttonText: this.props.children[childID],
    })

    if (this.state.currentChild < numberOfChildren - 1) {
      this.setState({currentChild: childID + 1})
    } else {
      this.setState({currentChild: 0})
    }
  }

  render () {
    return (
      <span onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff} role="jsx-a11y/no-static-element-interactions">{this.state.buttonText}</span>
    )
  }
}

export default Cycler
